import { Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import Warning from "../../../../styles/assets/svg/warning-icon.svg";
import { useContext } from "react";
import { AnalyticsContext } from "../../../../analytics";

interface Prop {
  message: string;
}

const WarningTip: React.FC<Prop> = ({ message }) => {
  const analytics = useContext(AnalyticsContext);
  analytics?.trackEvent({
    name: `Warning: ${message}`,
    properties: {
      page: "Availability",
      workflow: "Create",
      component: "WarningTip",
    },
  });

  return (
    <Stack direction="row" alignItems={"flex-start"} className={"warning-tip"}>
      <img src={Warning} alt={"error"} />
      <TypoGraphy typeClass={"semi-bold-font"} variant="body1">
        {message}
      </TypoGraphy>
    </Stack>
  );
};

export default WarningTip;
